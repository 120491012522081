import { withAuthenticationRequired } from '@auth0/auth0-react'
import Layout from 'manage-tritag/components/layout'
import CreateForm from 'manage-tritag/components/pages/venues/create-form'

const CreateVenuePage = () => {
  return (
    <Layout label1="Venues" label2="Create" url="/venues">
      <CreateForm />
    </Layout>
  )
}

export default withAuthenticationRequired(CreateVenuePage)
